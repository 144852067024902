<template>
  <anual-form
    okbtn="Crear observación"
    title="Crear observación del informe anual"
    :informe_anual.sync="data"
    :id_persona_rol_alumno="id_persona_rol_alumno"
    :nombre_modal="nombre_modal"
    :nombre_estudiante.sync="nombre_estudiante"
    :curso.sync="curso"
    @processForm="agregar"
    @resetData="resetData"
  />
</template>

<script>
import { mapActions } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

import AnualForm from './AnualForm.vue'

export default {
  components: {
    // COMPONENTES
    AnualForm,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        id_persona_rol_alumno: null,
        observacion: '',
        dias_trabajados: 0,
        dias_asistencia: 0,
        dias_inasistencia: 0,
        porcentaje_asistencia: 0,
      },
    }
  },
  props: {
    nombre_modal: {
      type: String,
      required: true,
    },
    id_persona_rol_alumno: {
      type: Number,
      required: true,
    },
    nombre_estudiante: {
      type: String,
      required: true,
    },
    id_cursos: {
      type: Number,
      required: true,
    },
    curso: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      createObservacion: 'informes/addObservacion',
      fetchObservaciones: 'informes/fetchObservaciones',
    }),
    agregar(informeParcial) {
      this.createObservacion(informeParcial).then(() => {
        const statusObservaciones = store.state.informes.status
        if (statusObservaciones === 'success') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Observación guardada 👍',
              icon: 'CheckIcon',
              text: 'La observación fue ingresada con éxito!',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 4000,
          })
          const data = {
            id_curso: this.id_cursos,
            tipo: 6, // TIPO 6: INFORME ANUAL
            id_periodo: null
          }
          this.fetchObservaciones(data)
          this.$bvModal.hide(this.nombre_modal)
        }
        else {
          this.$swal({
            title: 'Error!',
            text: store.state.informes.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        this.resetData()
      })
    },
    resetData() {
      this.data = []
      this.data = {
        id_persona_rol_alumno: null,
        observacion: '',
        dias_trabajados: 0,
        dias_asistencia: 0,
        dias_inasistencia: 0,
        porcentaje_asistencia: 0,
      }
    },
  },
}
</script>
