<template>
  <div
    v-if="$can('create', modulo)"
    class="d-flex align-items-center justify-content-end"
  >
    <personalidad-create
      v-if="Object.entries(personalidad).length === 0"
      :id_persona_rol_alumno.sync="id_persona_rol_alumno"
      :id_cursos.sync="id_cursos"
      :id_periodo.sync="id_periodo"
      :nombre_modal="`modal-create-personalidad-${id_persona_rol_alumno}`"
      :nombre_estudiante="nombre_estudiante"
      :curso.sync="curso"
    />
    <personalidad-update
      v-else
      :personalidad="personalidad"
      :id_persona_rol_alumno.sync="id_persona_rol_alumno"
      :id_cursos.sync="id_cursos"
      :id_periodo.sync="id_periodo"
      :nombre_modal="`modal-update-personalidad-${id_persona_rol_alumno}`"
      :nombre_estudiante="nombre_estudiante"
      :curso.sync="curso"
    />
    <b-button
      v-if="Object.entries(personalidad).length === 0"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="success"
      class="btn"
      title="Crear informe de personalidad"
      @click="btnAbrir(`modal-create-personalidad-${id_persona_rol_alumno}`)"
    >
      <span class="text-nowrap">Crear {{ texto }}</span>
    </b-button>
    <b-button
      v-else
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="primary"
      class="btn"
      title="Editar informe de personalidad"
      @click="btnAbrir(`modal-update-personalidad-${id_persona_rol_alumno}`)"
    >
      <span class="text-nowrap">Editar {{ texto }}</span>
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import PersonalidadCreate from './PersonalidadModal/PersonalidadCreate.vue'
import PersonalidadUpdate from './PersonalidadModal/PersonalidadUpdate.vue'

export default {
  components: {
    BButton,
    PersonalidadCreate,
    PersonalidadUpdate,
  },
  directives: {
    Ripple,
  },
  props: {
    personalidad: {
      type: Object,
      required: false,
    },
    texto: {
      type: String,
      required: true,
    },
    modulo: {
      type: String,
      required: false,
    },

    id_persona_rol_alumno: {
      type: Number,
      required: true,
    },
    id_cursos: {
      type: Number,
      required: true,
    },
    id_periodo: {
      type: Number,
      required: true,
    },
    nombre_estudiante: {
      type: String,
      required: true,
    },
    curso: {
      type: String,
      required: true,
    },

  },
  methods: {
    btnAbrir(nombre_modal) {
      this.$bvModal.show(nombre_modal)
    },
  },
}
</script>
