<template>
  <div
    v-if="$can('create', modulo)"
    class="d-flex align-items-center justify-content-end"
  >
    <parcial-create
      v-if="!observacion_parcial"
      :nombre_modal="`modal-create-parcial-${id_persona_rol_alumno}`"
      :id_persona_rol_alumno.sync="id_persona_rol_alumno"
      :id_periodo.sync="id_periodo"
      :id_cursos.sync="id_cursos"
      :nombre_estudiante="nombre_estudiante"
      :curso.sync="curso"
    />
    <parcial-update
      v-else
      :nombre_modal="`modal-update-parcial-${id_persona_rol_alumno}`"
      :observacion="observacion_parcial"
      :id_persona_rol_alumno.sync="id_persona_rol_alumno"
      :id_periodo.sync="id_periodo"
      :id_cursos.sync="id_cursos"
      :nombre_estudiante="nombre_estudiante"
      :curso.sync="curso"
    />

    <b-button
      v-if="!observacion_parcial"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="success"
      class="btn"
      @click="btnAbrir(`modal-create-parcial-${id_persona_rol_alumno}`)"
    >
      <span class="text-nowrap">Crear observación</span>
    </b-button>
    <b-button
      v-else
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="primary"
      class="btn"
      @click="btnAbrir(`modal-update-parcial-${id_persona_rol_alumno}`)"
    >
      <span class="text-nowrap">Editar observación</span>
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ParcialCreate from './ParcialModal/ParcialCreate.vue'
import ParcialUpdate from './ParcialModal/ParcialUpdate.vue'

export default {
  components: {
    BButton,
    ParcialCreate,
    ParcialUpdate,
  },
  directives: {
    Ripple,
  },
  props: {
    modulo: {
      type: String,
      required: false,
    },

    observacion_parcial:{
      type: Object,
      required: false,
    },
    id_persona_rol_alumno: {
      type: Number,
      required: true,
    },
    id_periodo: {
      type: Number,
      required: true,
    },
    id_cursos: {
      type: Number,
      required: true,
    },
    nombre_estudiante: {
      type: String,
      required: true,
    },
    curso: {
      type: String,
      required: true,
    },
  },
  methods: {
    btnAbrir(nombre_modal) {
      this.$bvModal.show(nombre_modal)
    },
  },
}
</script>
