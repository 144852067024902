<template>
  <b-overlay
    :show="spinner"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <parcial-form
      okbtn="Editar observación"
      title="Editar observación del informe parcial"
      :informe_parcial.sync="observacion"
      :id_persona_rol_alumno="id_persona_rol_alumno"
      :id_periodo="id_periodo"
      :nombre_modal="nombre_modal"
      :nombre_estudiante.sync="nombre_estudiante"
      :curso.sync="curso"
      @processForm="editar"
      @resetData="resetData"
    />
  </b-overlay>
</template>

<script>

import { BOverlay } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import ParcialForm from './ParcialForm.vue'

export default {
  components: {
    ParcialForm,
    BOverlay,
  },
  data() {
    return {
      spinner: false,
      asignaturas: [],
    }
  },
  props: {
    nombre_modal: {
      type: String,
      required: true,
    },
    observacion: {
      type: Object,
      required: true,
    },
    id_persona_rol_alumno: {
      type: Number,
      required: true,
    },
    nombre_estudiante: {
      type: String,
      required: true,
    },
    id_periodo: {
      type: Number,
      required: true,
    },
    id_cursos: {
      type: Number,
      required: true,
    },
    curso: {
      type: String,
      required: true,
    },
  },
  watch: {
  },
  methods: {
    ...mapActions({
      updateObservacion: 'informes/updateObservacion',
      fetchObservaciones: 'informes/fetchObservaciones',
    }),
    editar(informeParcial) {
      this.updateObservacion(informeParcial).then(() => {
        const statusObservaciones = store.state.informes.status
        if (statusObservaciones === 'success') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Observación guardada 👍',
              icon: 'CheckIcon',
              text: 'La observación fue editada con éxito!',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 4000,
          })
          const data = {
            id_curso: this.id_cursos,
            tipo: 1, // TIPO 1: INFORME PARCIAL
            id_periodo: this.id_periodo,
          }
          this.fetchObservaciones(data)
          this.$bvModal.hide(this.nombre_modal)
        }
        else {
          this.$swal({
            title: 'Error!',
            text: store.state.informes.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
    resetData() {
      const data = {
        id_curso: this.id_cursos,
        tipo: 1, // TIPO 1: INFORME PARCIAL
        id_periodo: this.id_periodo,
      }
      this.fetchObservaciones(data)
    },
  },
}
</script>
