import { render, staticRenderFns } from "./PersonalidadForm.vue?vue&type=template&id=0cf20336&"
import script from "./PersonalidadForm.vue?vue&type=script&lang=js&"
export * from "./PersonalidadForm.vue?vue&type=script&lang=js&"
import style0 from "./PersonalidadForm.vue?vue&type=style&index=0&id=0cf20336&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports