<template>
  <b-modal
    :id="nombre_modal"
    :title="title"
    centered
    size="lg"
    :hide-footer="true"
    :ok-title="okbtn"
    no-close-on-backdrop
    no-close-on-esc
    cancel-title="Cancelar"
    cancel-variant="outline-secondary"
    :ok-disabled="this.v$.informe_personalidad.$errors.length > 0"
    @ok.prevent="submitOption"
  >
    <b-overlay
      :show="cargando"
      spinner-variant="primary"
      variant="semi-dark"
    >
      <b-form
        ref="form"
          class="repeater-form"
      >

        <!-- PANEL DE INFORMACIÓN -->
        <b-row
          class=" mt-1 pb-0 pt-0 mb-2"
          style="padding-bottom: -10px !important;"
        >
            <!-- field: CURSO -->
            <b-col
              cols="12"
              md="6"
              class="text-left pl-2 pb-0 pt-0 mb-1 mt-0"
            >
              <b-card-text><b>Curso:</b> {{curso}}</b-card-text>
            </b-col>

            <b-col
              cols="12"
              md="12"
              class="text-left pl-2 pb-0 pt-0 mb-1 mt-0"
            >
              <b-card-text><b>Estudiante:</b> {{nombre_estudiante}}</b-card-text>
            </b-col>
        </b-row>

        <!-- TÍTULO TABLA -->
        <b-row
          class="border-bottom border-top mt-0 pb-0 pt-2 mb-0"
          style="padding-bottom: -10px !important;"
        >
            <b-col
              :cols="typeof informe_personalidad.indicadores !== 'undefined'
                ? 11 - conceptos.length
                : '12'"
              class="text-left pl-2 pb-0 pt-0 mb-0 mt-0"
            >
              <!-- :md="typeof informe_personalidad.indicadores !== 'undefined'
                ? 12 - conceptos.length
                : '12'" -->
              <b-card-title class="h5">Indicadores</b-card-title>
            </b-col>
            <b-col cols="1" md="1" class="d-none d-lg-block"></b-col>
            <b-col
              v-for="(concepto, index) in conceptos"
              cols="1"
              md="1"
              sm="1"
              class="text-left"
            >
              <b-card-title class="h5" :title="concepto.nombre">{{concepto.valor}}</b-card-title>
            </b-col>
        </b-row>

        <!-- INDICADORES -->
        <div
          v-for="(indicador, index) in informe_personalidad.indicadores"
          :id="indicador.id"
          :key="indicador.id"
          ref="row"
          class="border-bottom mb-0 pb-0 pt-0"

        >
          <b-row>
            <!-- field: INDICADOR -->
            <b-col
              :cols="11 - conceptos.length"
              class="text-left pt-50 pb-50 pl-2"
            >
              {{ indicador.indicador }}
            </b-col>
            <b-col cols="1" md="1" class="d-none d-lg-block"></b-col>

            <!-- field: CONCEPTO -->
            <b-col
              v-for="(concepto, index) in conceptos"
              cols="1"
              md="1"
              class="mt-50"
            >
              <b-form-radio-group
                :id="`radio-group-${indicador.id}`"
                v-model="indicador.id_concepto_valores"
                :name="`radio-sub-component-${indicador.id}`"
              >

                <b-form-radio
                  :value="concepto.id"
                  :autofocus="true"
                />

              </b-form-radio-group>
            </b-col>
          </b-row>

        </div>

        <!-- OBSERVACION -->
        <b-row
          class="border-bottom border-top mt-0 pb-0 pt-2 mb-0"
          style="padding-bottom: -10px !important;"
        >
          <b-col
            cols="12"
            md="12"
            class="text-left pl-2 pb-0 pt-0 mb-0 mt-0"
          >
            <b-form-group
              label="Observación *"
              label-for="observacion"
            >
              <b-form-textarea
                id="observacion"
                v-model="informe_personalidad.observacion"
                placeholder="Ingresa una observación"
                :state="v$.informe_personalidad.observacion.$error === true
                  ? false
                  : null"
                @blur.native="v$.informe_personalidad.observacion.$touch"
              />
              <b-form-invalid-feedback
                v-if="v$.informe_personalidad.observacion.$error"
                id="observacionInfo"
                class="text-right"
              >
                <p v-for="error of v$.informe_personalidad.observacion.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- ACCIONES FOOTER -->
        <div class="text-right pt-3">
          <!-- Action Buttons -->
          <b-button
            variant="outline-secondary"
            class="mr-1"
            @click="closeModal"
          >
            Cancelar
          </b-button>

          <b-button
            v-if="$can('create' , 'informePerso') || $can('update', 'informePerso')"
            variant="primary"
            class="mr-25"
            @click="submitOption"
          >
            {{ okbtn }}
          </b-button>
        </div>
      </b-form>
    </b-overlay>
  </b-modal>
</template>

<script>

// ETIQUETAS
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
  BMedia, BButton, BAvatar, BOverlay, BFormDatepicker, BFormTimepicker,
  BFormTextarea, BModal, VBModal, BInputGroup, BInputGroupAppend,
  BCardTitle, BCardText, BFormRadio, BFormRadioGroup,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import { mapGetters } from 'vuex'
// CLEAVE
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

// FORMATOS
import { formatos } from '@core/mixins/ui/formatos'

// VALIDACIONES
import useVuelidate from '@vuelidate/core'
import { required, maxLength, email, helpers } from '@vuelidate/validators'

// COMPONENTES RECICLADOS
import colLinea from '../../../components/Form/colLinea.vue'
import btnSubmit from '../../../components/Form/btnSubmit.vue'

export default {
  components: {
    // ETIQUETAS
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BMedia,
    BButton,
    BAvatar,
    BOverlay,
    BFormDatepicker,
    BFormTimepicker,
    BFormTextarea,
    BModal,
    BInputGroup,
    BInputGroupAppend,
    BCardTitle,
    BCardText,
    VBModal,
    Cleave,
    BFormRadio,
    BFormRadioGroup,

    // COMPONENTES RECICLADO
    colLinea,
    btnSubmit,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [formatos],
  data() {
    return {
      cargando: false,
      conceptos: [],
    }
  },
  computed: {
    ...mapGetters({
      getPersonalidadGrado: 'personalidades/getPersonalidadGrado',
    }),
  },
  props: {
    informe_personalidad: {
      type: Object,
      required: true,
    },
    nombre_modal: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    id_persona_rol_alumno: {
      type: Number,
      required: true,
    },
    id_cursos: {
      type: Number,
      required: true,
    },
    id_periodo: {
      type: Number,
      required: true,
    },
    curso: {
      type: String,
      required: true,
    },
    nombre_estudiante: {
      type: String,
      required: true,
    },
    okbtn: {
      type: String,
      default: 'Guardar informe',
    },
  },
  watch: {
    informe_personalidad(informe_personalidad) {
      this.setInformePersonalidad(informe_personalidad)
    },
  },
  validations() {
    return {
      informe_personalidad: {
        observacion: {
          // $autoDirty: true,
          required: helpers.withMessage('El campo es requerido.', required),
          maxLength: helpers.withMessage('Debe tener un máximo de 200 caracteres.', maxLength(200)),
        },
      }
    }
  },
  mounted() {
    this.setInformePersonalidad(this.informe_personalidad)
    this.setConceptos(this.getPersonalidadGrado)
  },
  methods: {
    setConceptos(getPersonalidadGrado) {
      this.conceptos = getPersonalidadGrado[0].conceptos
    },
    setInformePersonalidad(indicadores) {
      this.informe_personalidad.indicadores = []
      this.informe_personalidad.id_persona_rol_estudiante = this.id_persona_rol_alumno
      this.informe_personalidad.id_periodo = this.id_periodo

      this.getPersonalidadGrado.forEach(indicador => {
        let id_concepto_valores = null
        if (typeof this.informe_personalidad.personalidad_valores !== 'undefined') {
          const concepto = this.informe_personalidad.personalidad_valores.find(pv => pv.id_indicador === indicador.id)
          id_concepto_valores = concepto.id_concepto_valores
        }
        this.informe_personalidad.indicadores.push({
          id: indicador.id,
          id_concepto_valores,
          conceptos: indicador.conceptos,
          id_grupo_conceptos: indicador.id_concepto,
          indicador: indicador.indicador,
        })
      })
    },
    submitOption() {
      this.cargando = true
      this.v$.informe_personalidad.$touch()
      if (!this.v$.informe_personalidad.$invalid) {
        this.$emit('processForm', this.informe_personalidad)
        this.v$.informe_personalidad.$reset()
      }
      this.cargando = false
    },
    closeModal() {
      this.$bvModal.hide(this.nombre_modal)
      this.$emit('resetData')
      this.v$.informe_personalidad.$reset()
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
