<template>
  <b-overlay
    :show="spinner"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <personalidad-form
      okbtn="Editar informe personalidad"
      title="Editar informe de personalidad"
      :informe_personalidad.sync="personalidad"
      :id_persona_rol_alumno="id_persona_rol_alumno"
      :id_cursos="id_cursos"
      :id_periodo="id_periodo"
      :nombre_modal="nombre_modal"
      :nombre_estudiante.sync="nombre_estudiante"
      :curso.sync="curso"
      @processForm="editar"
    />
  </b-overlay>
</template>

<script>

import { BOverlay } from 'bootstrap-vue'
import { mapActions } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import PersonalidadForm from './PersonalidadForm.vue'

export default {
  components: {
    PersonalidadForm,
    BOverlay,
  },
  data() {
    return {
      spinner: false,
      asignaturas: [],
    }
  },
  props: {
    personalidad: {
      type: Object,
      required: false,
    },
    nombre_estudiante: {
      type: String,
      required: true,
    },
    id_persona_rol_alumno: {
      type: Number,
      required: true,
    },
    id_cursos: {
      type: Number,
      required: true,
    },
    id_periodo: {
      type: Number,
      required: true,
    },
    nombre_modal: {
      type: String,
      required: true,
    },
    curso: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      fetchInformesPersonalidad: 'informesPersonalidad/fetchInformesPersonalidad',
      updateInformePersonalidad: 'informesPersonalidad/updateInformePersonalidad',
    }),
    editar(informe_personalidad) {
      this.updateInformePersonalidad(informe_personalidad).then(() => {
        const statusPersonalidad = store.state.informesPersonalidad.status
        if (statusPersonalidad === 'success') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Informe de personalidad guardado 👍',
              icon: 'CheckIcon',
              text: 'El informe de personalidad fue editado con éxito!',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 4000,
          })
          this.$bvModal.hide(this.nombre_modal)
          const data = {
            id_curso: this.id_cursos,
            id_periodo: this.id_periodo
          }
          this.fetchInformesPersonalidad(data)
        }
        else {
          this.$swal({
            title: 'Error!',
            text: store.state.informesPersonalidad.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
  },
}
</script>
